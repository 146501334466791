import { makeStyles } from '@material-ui/core/styles'

const globalStyles = makeStyles(theme => ({
  '@global': {
    body: {
      fontFamily: 'Exo, sans-serif',

      '& .jobii-slick-dots li button': {
        '&:before': {
          fontSize: '12px'
        }
      }
    }
  },
  rootPage: {
    minHeight: '100vh',
    backgroundColor: '#fff'
  },
  pageBox: {
    padding: 50,
    marginBottom: 50,
  },
  '@media only screen and (max-width: 580px)': {
  }
}));

export default globalStyles