export const doScrolling = (elementY, duration) => { 
  let startingY = window.pageYOffset;
  let diff = elementY - startingY;
  let start;

  if (typeof window !== 'undefined') {
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      let time = timestamp - start;
      let percent = Math.min(time / duration, 1);
  
      window.scrollTo(0, startingY + diff * percent);
        if (time < duration) {
        window.requestAnimationFrame(step);
      }
    })
  }
}

export const scrollTo = (id, duration) => {
  const element = document.querySelector(`#${id}`)

  if (element) {
    doScrolling(element.offsetTop, duration)
  }
}