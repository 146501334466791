import React from 'react'
import footerStyles from '../../styles/footer'
import { Grid, Container, ListItem, List } from '@material-ui/core'
import LogoDesktop from '../Image/logo'

const Footer = () => {
  const classes = footerStyles()

  /*const InputFooter = ({ label }) => (
    <TextField
      label={label}
      fullWidth
      InputLabelProps={{
        style: { color: '#fff' },
      }}
      InputProps={{
        classes: {
          root: classes.notchedOutline
        }
      }}
    />
  )*/

  return (
    <footer className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <LogoDesktop />

            <p className={classes.companyText}>
              Somos uma startup focada em facilitar o dia-a-dia dos RHs, aliando tecnologia e simplicidade para indicar os melhores profissionais para as vagas certas.
            </p>
          </Grid>

          <Grid item xs={12} sm={4} style={{display: 'flex', alignItems: 'center'}}>
            {/*
            <span className={classes.footerTitle}>Entre em contato</span>

            <form className={classes.formSubscribe}>
              <InputFooter
                label="Nome"
              />

              <InputFooter
                label="E-mail"
              />

              <Button
                variant="contained"
                className={classes.sendButton}
                color="primary"
              >
                Enviar
              </Button>
            </form>
            */}
            <List className={classes.socialList}>
              <ListItem>
                <a className={classes.socialLink} href="https://youtube.com/" target="_blank" rel="noopener noreferrer">
                  YouTube
                </a>
              </ListItem>

              <ListItem>
                <a className={classes.socialLink} href="https://instagram.com/" target="_blank" rel="noopener noreferrer">
                  Instagram
                </a>
              </ListItem>

              <ListItem>
                <a className={classes.socialLink} href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <p>
        Av.Ipiranga, 6681 Prédio 99 A | Sala 811 - Porto Alegre | RS | Brasil - Fone (51) 3023-2350
        </p>
      </Container>
    </footer>
  )
}

export default Footer