import { makeStyles } from '@material-ui/core/styles'

const footerStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.tertiary.main,
    padding: '64px 0',
    color: '#fff',
    fontWeight: 600,
  },
  companyText: {
    fontSize: '20px',
    lineHeight: '36px',
  },
  socialLink: {
    fontSize: '24px',
    color: '#fff',

    '&:hover': {
      textDecoration: 'underline !important'
    }
  },
  formSubscribe: {
    padding: '32px 0'
  },
  sendButton: {
    ...theme.defaultButton,
    marginTop: 50
  },
  notchedOutline: {
    color: '#fff',

    '&:before': {
      borderBottom: '1px solid #fff'
    },

    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #fff'
    }
  },
  socialList: {
    display: 'flex',
    marginTop: 50,

    '& li': {
      width: 'auto',
      display: 'inline-block',
    },

    '& li:first-child': {
      paddingLeft: 0
    }
  },
  footerTitle: {
    fontSize: '22px',
  },
  '@media only screen and (max-width: 580px)': {
    socialList: {
      justifyContent: 'center',
      marginBottom: 50,
    },
    sendButton: {
      width: '100%'
    },
    socialLink: {
      fontSize: '21px'
    }
  }
}));

export default footerStyles