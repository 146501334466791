import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import LogoDesktop from '../Image/logo'
import { Container, Grid, IconButton, Button, ListItem, List, SwipeableDrawer } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import headerStyles from '../../styles/header'
import { scrollTo } from '../../services/utils'

const Header = () => {
  const classes = headerStyles()

  const [isOpenMenu, setIsOpenMenu] = React.useState(false)
  const toggleDrawer = isOpen => () => {
    setIsOpenMenu(isOpen)
  }

  const [hovered, setHovered] = React.useState()
  const toggleHover = (index) => () => {
    setHovered(index)
  }

  const getMenuItems = (className) => (
    <List className={className}>
      <ListItem className={classes.navMenuButton}>
        <a
          href="#features"
          onClick={() => { scrollTo('features', 300);setIsOpenMenu(false) }}
          onMouseEnter={toggleHover(0)}
          onMouseLeave={toggleHover(null)}
          className={hovered === 0 ? 'active' : ''}
        >
          Vantagens
        </a>
      </ListItem>

      <ListItem className={classes.navMenuButton}>
        <a
          href="#explore"
          onClick={() => { scrollTo('explore', 400);setIsOpenMenu(false) }}
          onMouseEnter={toggleHover(1)}
          onMouseLeave={toggleHover(null)}
          className={hovered === 1 ? 'active' : ''}
        >
          Explore
        </a>
      </ListItem>
    </List>
  )

  return (
    <div className={classes.root}>
      <AppBar
        position="sticky"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar>
          <Container maxWidth="xl">
            <Grid
              justify="space-between"
              container 
            >
              <Grid item className={classes.root}>
                <IconButton aria-label="Abrir Menu" onClick={toggleDrawer(true)} className={classes.mobileToggle}>
                  <MenuIcon />
                </IconButton>

                <div className={classes.logoDesktop}>
                  <Link to="/">
                    <LogoDesktop />
                  </Link>
                </div>

                {getMenuItems(classes.navMenu)}

                <Button
                  variant="contained"
                  className={classes.loginButton}
                  color="primary"
                  href={'https://southsystem.jobii.com.br/'}
                >
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer anchor="left" open={isOpenMenu} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        <div className={classes.menuHeader}>
          <Link to="/">
            <LogoDesktop />
          </Link>
        </div>

        {getMenuItems(classes.listMenuMobile)}
      </SwipeableDrawer>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
