import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../Header'
import CssBaseline from '@material-ui/core/CssBaseline'
import globalStyles from '../../styles/global'
import Footer from '../Footer'

const Layout = ({ children }) => {
  const globalClasses = globalStyles()

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div
          className={globalClasses.rootPage}
        >
          <CssBaseline />

          <Header siteTitle={data.site.siteMetadata.title} />

          <div
            style={{
              margin: `0 auto`,
              maxWidth: '100%'
            }}
          >

            <style>{`a { text-decoration: none !important; }`}</style>

            <main>{children}</main>
          </div>

          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
