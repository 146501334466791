import { makeStyles } from '@material-ui/core/styles'

const headerStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'transparent',
    height: 100,
    display: 'flex',
    justifyContent: 'center'
  },
  loginButton: {
    color: '#fff',
    position: 'absolute',
    right: 0,
    textTransform: 'capitalize',
    boxShadow: 'none',
    fontSize: 17,
    ...theme.defaultButton,

    '&:hover': {
      background: theme.palette.contrast.main,
    }
  },
  mobileToggle: {
    display: 'none'
  },
  navMenu: {
    display: 'flex',
    padding: '0 24px 0 2%',

    '& a': {
      color: theme.palette.primary.main,
      padding: '8px 4px',
      fontSize: 17,
      cursor: 'pointer',

      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        left: '51%',
        right: '51%',
        bottom: 5,
        background: theme.palette.primary.main,
        height: 3,
        transitionProperty: 'left,right',
        transitionDuration: '.3s',
        transitionTimingFunction: 'ease-out',
      },

      '&.active:before': {
        left: 0,
        right: 0
      }
    }
  },
  navMenuButton: {
    borderRadius: '50px',
    marginRight: 22
  },
  '@media only screen and (max-width: 580px)': {
    appBar: {
      '& div:nth-child(1)': {
        paddingLeft: 1,
        paddingRight: 3
      }
    },
    mobileToggle: {
      display: 'block',
      color: '#282828',

      '& svg': {
        fontSize: '32px'
      }
    },
    navMenu: {
      display: 'none',
    },
    menuHeader: {
      padding: '24px 16px',
      background: theme.palette.tertiary.main
    },
    listMenuMobile: {
      width: 245,

      '& li': {
        padding: 0
      },

      '& a': {
        color: theme.palette.primary.main,
        display: 'block',
        padding: '8px 16px',
        width: '100%',
        fontSize: 18,
      }
    }
  }
}))

export default headerStyles